import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jamesabaja/MedGrocer/maersk-pbm/src/components/Layout/Layout.js";
import { Link } from "gatsby";
import Container from "layout/Container";
import SEO from "layout/SEO";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container isCentered mdxType="Container">
      <SEO title="Terms and Conditions" mdxType="SEO" />
      <h2>{`Terms and Conditions`}</h2>
      <hr />
      <div className="content is-medium">
        <h3>{`Data Privacy`}</h3>
        <ol>
          <li parentName="ol">{`The above information is correct and I provide MedGrocer the authority to collect, store, and use my information for the Medical Allowance Program and related administrative activities. Subject to certain exceptions allowed by the law, I may revise my personal information and inform MedGrocer to update and store the same. MedGrocer may store my personal information for a period of six (6) years from the end of the program without any further consent needed from my end.`}</li>
          <li parentName="ol">{`Maersk acknowledges and respects the privacy of individuals.`}</li>
          <li parentName="ol">{`Maersk has engaged MedGrocer to collect and hold my information in order to organize my participation in the Medical Allowance Program and to communicate with me regarding the program. By completing this form and providing the information requested, I consent to Maersk, MedGrocer, and their partners using that information for the purposes outlined. Maersk may access and disclose the information to respond to regulatory authorities or if required by law.`}</li>
          <li parentName="ol">{`I am not obliged to provide the information and I may choose not to. If I do not provide this information, Maersk may not be able to provide the needed support to me under the program or administer the program effectively or at all.`}</li>
          <li parentName="ol">{`If I would like to access, update, or correct my information, or if I do not want my information retained on the database, I should contact MedGrocer.`}</li>
        </ol>
        <h3>{`Program End`}</h3>
        <p>{`The program with MedGrocer will run from May 5, 2020, to May 5, 2021.`}</p>
        <h3>{`Waiver`}</h3>
        <p>{`I will indemnify and hold Maersk, MedGrocer, and their partners free from any liabilities that may arise as a consequence of my decision to join the Medical Allowance Program. I also acknowledge that Maersk or MedGrocer shall not be responsible nor liable for any loss or damage incurred or suffered as a consequence of:`}</p>
        <p>{`A) Any delay or inability of MedGrocer to perform any of its obligations due to any mechanical, data processing, telecommunication failure, act of God, civil disturbance, any event outside MedGrocer’s control, or as a consequence of any fraud or forgery; and`}</p>
        <p>{`B) Any damage to or loss of or inability to retrieve any data that may be stored in the MedGrocer database.`}</p>
      </div>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      